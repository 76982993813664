import { render, staticRenderFns } from "./ListCourse.vue?vue&type=template&id=4ed37e00&scoped=true&"
import script from "./ListCourse.vue?vue&type=script&lang=js&"
export * from "./ListCourse.vue?vue&type=script&lang=js&"
import style0 from "./ListCourse.vue?vue&type=style&index=0&id=4ed37e00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed37e00",
  null
  
)

export default component.exports