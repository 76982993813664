<template>
  <div class="row card">

    <div class="card-body col-md-12">
      <div class="col-md-12">
        <div class="list-block" v-for="(item, index) in list_course_studying" :key="index">
          <div class="d-lg-flex">
            <div class="border-solid pr-5 width-css-d1">
              <h3 class="font-weight-bold">{{item.title}}</h3>
              <p>
                <span class="bg-orange text-white rounded p-1 mw-75">Chứng nhận {{item.title}}</span>
              </p>
              <p class="text-red">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-stopwatch" viewBox="0 0 16 16">
                  <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"/>
                  <path
                      d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"/>
                </svg>
              Deadline: {{ candidate?.deadline ? formatDateTimeVietnam(candidate?.deadline, true) : formatDateTimeVietnam(item?.deadline, false) }}
              </p>
            </div>
            <div class="pl-md-4 pt-md-4 width-css-d2">
              {{item.description}}
            </div>
          </div>
          <div class="mt-4 " v-if="Object.values(item.courses).length > 0">
            <VueSlickCarousel
                v-bind="settings"
                @beforeChange="beforeChangeSlick"
                @afterChange="afterChangeSlick"
                class=""
            >
            <div class="rounded-xl position-relative cursor " style="width: 473px" v-for="(item_prop, index) in item.courses"
                 :key="index">
              <div class="bg-gradient position-absolute" @click="redirectQuantumleap(item_prop)"></div>
              <div class="position-absolute px-4 bottom-0 w-100" style="width: 473px">
                <div class="tesst"></div>
                <p class="text-yellow bold m-0">Khóa học</p>
                <div class="d-flex justify-content-between align-items-end">
                  <h3 @click="redirectQuantumleap(item_prop)" class="text-white mw-75">
                      {{ item_prop.title.length > 100 ? item_prop.title.slice(0,100) + '...' : item_prop.title }}
                  </h3>
                  <p class="text-white flex"><span class="rounded-circle px-2 mr-2" :class="checkStatus(item_prop.status).color"></span>
                    {{ checkStatus(item_prop.status).text }}</p>
                </div>
              </div>
              <div class="">
                <img :src="item_prop.image" alt="" class="">
              </div>

            </div>
            </VueSlickCarousel>
          </div>
        </div>
        <div class="list-block mt-6" v-for="(item, index) in list_course_pass" :key="index">
          <div class="d-lg-flex">
            <div class="border-solid pr-5 width-css-d1">
              <h3 class="font-weight-bold">{{item.title}}</h3>
              <p class="d-flex justify-content-between">
              <span class="bg-green text-white rounded p-1 mw-75">
                Chứng nhận2 {{item.title}}
              </span>
                <span class="pt-1">
                <span class="bg-green rounded-circle px-2 mr-1"></span>
                Đã cấp
              </span>
              </p>
              <p class="text-red">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-stopwatch" viewBox="0 0 16 16">
                  <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"/>
                  <path
                      d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"/>
                </svg>
                Deadline: {{ candidate?.deadline ? formatDateTimeVietnam(candidate?.deadline, true) : formatDateTimeVietnam(item?.deadline, false) }}
              </p>
            </div>
            <div class="pl-md-4 pt-md-4 width-css-d2">
              <p class="">
                {{item.description}}
              </p>
            </div>
          </div>
          <div class=" mt-4" v-if="Object.values(item.courses).length > 0">
            <VueSlickCarousel
                :arrows="true"
                :dots="true"
                v-bind="settings"
                @beforeChange="beforeChangeSlick"
                @afterChange="afterChangeSlick"
                class="h-25 container-carousel"
            >
              <div class=" rounded position-relative cursor flex flex-row"
                   v-for="(item_prop,index) in item.courses"
                   :key="index">
                <div class="bg-gradient position-absolute" @click="redirectQuantumleap(item_prop)"></div>
                <div class="position-absolute px-4 bottom-0 w-100">
                  <p class="text-yellow bold">Khóa học</p>
                  <div class="d-flex justify-content-between align-items-end">
                    <h3 @click="redirectQuantumleap(item_prop)" class="text-white mw-75">{{ item_prop.title.length > 100 ? item_prop.title.slice(0,100) + '...' : item_prop.title }}</h3>
                    <p class="text-white"><span class="rounded-circle px-2 mr-2" :class="checkStatus(item_prop.status).color"></span>
                      {{ checkStatus(item_prop.status).text }}</p>
                  </div>
                </div>
                <img :src="item_prop.image" alt="" class="w-full rounded">
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import '@/assets/css/advise.css';
import Slick from 'vue-slick';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {
  GET_CANDIDATE_LOGIN,
  GET_LIST_COURSE, SHOW_QUANTURM_LEAP,
} from "@/core/services/store/course/courseLevel.module";
import {mapGetters, mapState} from "vuex";
import {CONFIRM} from "@/core/services/store/profile.module";
import {CONFIRM_CONFIRMED} from "@/core/services/store/user/users.module";

export default {
  name: "ListCourse",
  // eslint-disable-next-line vue/no-unused-components
  components: {Slick, VueSlickCarousel},
  data() {
    return {
      settings: this.checkReponsive(),
      list_course_pass: {},
      list_course_studying: {},
      candidate: {},
      user_token: {},
      titleCourseStudying: '',
      slideIsWipe: true,
      draging: false,
    }
  },
  mounted() {
    if (this.currentUser.profile.candidate &&
        this.currentUser.profile?.candidate.status == 3 &&
        this.currentUser.profile.account_type_id != 4 &&
        this.currentUser.profile?.status_verify != 2
    ) {
      this.$router.push({path: '/profile'});
    }
    this.getCandidate();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Học tập", icon: 'far fa-sitemap'},
      {title: "Danh sách khóa học", icon: 'far fa-chart-bar'}
    ]);
    this.checkReponsive();
    this.getListCoursePass();
    this.getListCourseStudying();
  },
  methods: {
    redirectQuantumleap(item) {
      if (this.draging == false) {
        this.$store.dispatch(SHOW_QUANTURM_LEAP, {id: item.id, token: this.user_token.token}).then((response) => {
          setTimeout(() => {
            window.open(response.data,'_blank')
          })
        }).catch((response) => {
        })
      }

    },

    checkReponsive() {
      let widthWindow = window.innerWidth;
      let result = {};
      if (widthWindow >= 1920) {
        result = {
          "slidesToShow": 4,
          "slidesToScroll": 4,
          "variableWidth": false,
          "centerMode": false,
          "centerPadding": '0',
          "dots": false,
          "arrows": true,
          "appendArrows": '.arrows'
        }
      }

      if (widthWindow >= 1280) {
        result = {
          "slidesToShow": 4,
          "slidesToScroll": 4,
          "variableWidth": false,
          "centerMode": false,
          "centerPadding": '0',
          "dots": false,
          "arrows": true,
          "appendArrows": '.arrows'
        }
      } else if (widthWindow >= 1024 && widthWindow < 1280) {
        result = {
          "slidesToShow": 3,
          "slidesToScroll": 3,
          "variableWidth": false,
          "centerMode": false,
          "centerPadding": '0',
          "dots": false,
          "arrows": true,
          "appendArrows": '.arrows'
        }
      } else if (widthWindow > 425 && widthWindow <= 768) {
        result = {
          "slidesToShow": 2,
          "slidesToScroll": 2,
          "variableWidth": false,
          "centerMode": false,
          "centerPadding": '0',
          "dots": false,
          "arrows": true,
          "appendArrows": '.arrows'
        }
      } else {
        result = {
          "slidesToShow": 1,
          "slidesToScroll": 1,
          "variableWidth": false,
          "centerMode": false,
          "centerPadding": '0',
          "dots": false,
          "arrows": true,
          "appendArrows": '.arrows'
        }
      }
      return result;
    },
    getListCoursePass() {
      let status = 1;
      this.$store.dispatch(GET_LIST_COURSE, {status: status}).then((response) => {
        let programs = response.data.data.filter((d)=>d.isCompleted == true);
        this.list_course_pass = programs;
        if (response.data.checkPass == true && this.currentUser.is_confirm < CONFIRM_CONFIRMED) {
          this.$router.push({path: '/profile'});
        }
      }).catch((response) => {

      })
    },
    getListCourseStudying() {
      let status = 0;
      this.$store.dispatch(GET_LIST_COURSE, {status: status}).then((response) => {
        let programs = response.data.data.filter((d)=>d.isCompleted == false);
        console.log(programs, 'a')
        this.list_course_studying = programs;
        if (response.data.checkPass == true && this.currentUser.is_confirm < CONFIRM_CONFIRMED) {
          this.$router.push({path: '/profile'});
        }
      }).catch((response) => {

      })
    },
    formatDateTimeVietnam(value, type) {
      if (!value) {
        return '';
      }
      if (value) {
        let date = this.$moment((value)).format('HH:mm:ss DD-MM-YYYY')
        if (date === 'Invalid date') {
          return 'Không có thông tin';
        }
        if (type == false) {
          let date = this.$moment((value)).format('DD-MM-YYYY')
          return date + ' 09:00';
        }
        return date;
      }
    },
    getCandidate() {
      this.$store.dispatch(GET_CANDIDATE_LOGIN).then((response) => {
        this.candidate = response.data[0];
        this.user_token = response.data[1];
      }).catch((response) => {

      })
    },
    checkStatus(status) {
      let text;
      let color;
      switch (status) {
        case 0:
          text = 'Chưa học';
          color = 'bg-gray';
          break;
        case 1:
          text = 'Đang học';
          color = 'bg-orange';
          break;
        case 2:
          text = 'Đã học';
          color = 'bg-blue';
          break;
        case 3:
          text = 'Hoàn thành';
          color = 'bg-green';
          break;
      }
      return {text: text, color:color};
    },
    beforeChangeSlick(event) {
      this.draging = true
    },
    afterChangeSlick(event) {
      this.draging = false
    },
    swipe(event) {
      this.draging = true
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(['currentUser'])
  },
}
</script>

<style scoped>
.bg-violet {
  background: #472f92 !important;
}

.text-violet {
  color: #472f92 !important;
}

.text-red {
  color: #ff2218 !important;
}

.bg-green {
  background: #1dc93a !important;
}

.bg-gray {
  background: gray !important;
}
.bg-blue {
  background: blue !important;
}

.bg-red {
  background: #ff2218 !important;
}

.bg-orange {
  background: orange;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.text-yellow {
  color: #ffc14f;
}

img.w-full.rounded {
  height: 300px;
  width: 98%;
}

.position-absolute.px-4 {
  bottom: 0px;
}

.border-solid {
  border-right: 2px black solid;
}
.cursor {
  cursor: pointer;
}
@media screen and (max-width: 1920px) {
  .container-carousel {
    width: 1800px;
  }
  .width-css-d1 {
    width: 20%;
  }
  .width-css-d2 {
    width: 72%;
  }
  img {
    border-radius: 30px !important;
    width: 440px !important;
    height: 272px !important;
  }
}
@media screen and (max-width: 1520px) {
  .container-carousel {
    width: 1430px;
  }
  .width-css-d1 {
    width: 20%;
  }
  .width-css-d2 {
    width: 59%;
  }
  img {
    border-radius: 30px !important;
    width: 350px !important;
    height: 230px !important;
  }
}
@media screen and (max-width: 1440px) {
  .container-carousel {
    width: 1295px;
  }
  .width-css-d1 {
    width: 24%;
  }
  .width-css-d2 {
    width: 74%;
  }
  img.w-full.rounded {
    height: 230px;
    width: 98%;
  }
  img {
    border-radius: 30px !important;
    width: 315px !important;
    height: 210px !important;
  }
}

@media screen and (max-width: 1280px) {
  .container-carousel {
    width: 1200px;
  }
  .width-css-d1 {
    width: 27%;
  }
  .width-css-d2 {
    width: 72%;
  }
  img.w-full.rounded {
    height: 230px;
    width: 98%;
  }
  img {
    border-radius: 30px !important;
    width: 295px !important;
    height: 210px !important;
  }
}
@media screen and (max-width: 1024px) {
  .container-carousel {
    width: 950px;
  }
  .width-css-d1 {
    width: 28%;
  }
  .width-css-d2 {
    width: 65%;
  }
  img {
    border-radius: 30px !important;
    width: 310px !important;
    height: 220px !important;
  }
}
@media screen and (max-width: 768px) {
  .container-carousel {
    width: 690px;
  }
  .width-css-d1 {
    width: 100%;
  }
  .width-css-d2 {
    width: 100%;
  }
  img {
    border-radius: 30px !important;
    width: 340px !important;
    height: 220px !important;
  }
}
@media screen and (max-width: 425px) {
  .container-carousel {
    width: 300px;
  }
  img {
    border-radius: 30px !important;
    width: 300px !important;
    height: 220px !important;
  }
  .bg-gradient {
    width: 98%;
  }
}

.mw-75 {
  max-width: 75%;
}

img {
  object-fit: cover;
}
img {
  border-radius: 30px !important;
  width: 98% !important;
  /*height: 272px !important;*/
}
.bg-gradient {
  height: 100%;
  width: 98%;
  background-image: linear-gradient(#21154800, #211548);
  border-radius: 30px !important;
}
</style>